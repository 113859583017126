<template>
	<div>
		<div v-if="mode === 'grid'">
			<div class="style-card-row">
				<template v-for="card of cards">
					<div class="style-card-col" v-bind:key="`style-${card._id}`" v-if="card.key">
						<StyleCard
							:item="card"
							:toggleActiveStyle="toggleActiveStyle"
							:draft="draft"
							:disabled="disabled">
						</StyleCard>
					</div>
					<div class="style-card-col" :key="`offer-${card._id}`" v-else>
						<StyleCard :offer="card"  />
					</div>
				</template>
			</div>
		</div>
		<div v-if="mode === 'list'">
			<transition-group class="style-card-list" name="slide-fade">
				<div class="style-card-item" v-for="style of styles" v-bind:key="style.key">
					<b-card class="style-card">
						<b-card-text>
							<b-row>
								<b-col class="ellipsis pr-0 align-self-center">
									<a href="" class="url" v-if="style.key.match('http')">{{ getUrlParts(style.key).hostname }}<span class="text-muted">{{ getUrlParts(style.key).pathname }}{{ getUrlParts(style.key).search }}{{ getUrlParts(style.key).hash }}</span></a>
									<a href="" class="url" v-else>{{ style.key }}</a>
								</b-col>
								<b-col cols="auto">
									<b-row>
										<b-col cols="auto" class="align-self-center pr-0">
											<b-form-checkbox name="check-button" v-b-tooltip.hover title="Toggle stylesheet on" switch v-model="style.active" @change="toggleActiveStyle(style)"></b-form-checkbox>
										</b-col>
										<b-col cols="auto" class="align-self-center">
											<StyleMenu :item="style"></StyleMenu>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-card-text>
					</b-card>
				</div>
			</transition-group>
		</div>
	</div>
</template>

<script>
	const StyleCard = () => import('@/components/StyleCard');
	const StyleMenu = () => import('@/components/StyleMenu');
	export default {
		name: 'StyleList',
		components: {
			StyleMenu,
			StyleCard
		},
		props: [
			'styles',
			'mode',
			'draft',
			'disabled'
		],
		data () {
			return {
				cards: [],
				offers: [],
				y: 0
			}
		},
		computed: {
			Offers () {
				const { Offers } = this.$FeathersVuex.api;
				return Offers;
			}
		},
		methods: {
			offer () {
				let offer;
				if (typeof this.offers[this.y] === "undefined") {
					return false;
				}
				offer = this.offers[this.y];
				this.y++;
				return offer;
			},
			toggleActiveStyle (style) {
				style.active = !style.active;
				style.patch();
			},
			shuffleCards () {
				this.y = 0;
				if (!this.isPro) {
					this.Offers.find({ query: { isActive: true, $sort: { order: 1 } } }).then(res => {
						this.offers = res.data;
						this.cards = [];
						for (let i=0; i<this.styles.length; i++) {
							const style = this.styles[i];
							this.cards.push(style);
							if ( this.styles.length <= 2 && i == this.styles.length - 1 && this.offers.length ) {
								this.cards.push(this.offer());
							} else if ( i%3 === 0 && this.offers.length ) {
								const offer = this.offer();
								if (offer) {
									this.cards.push(offer);
								}
							}
						}
					});
				} else {
					this.cards = this.styles;
				}
			}
		},
		watch: {
			styles () {
				this.shuffleCards();
			}
		},
		async mounted () {
			this.shuffleCards();
		}
	}
</script>

<style lang="scss" scoped>
.style-card-row {
	display: grid;
	grid-template-columns: auto;
	column-gap: 2rem;
	row-gap: 2rem;
	padding: 0 2rem;

	@media (min-width: 800px) {
		grid-template-columns: 1fr;
	}

	@media (min-width: 1000px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (min-width: 1400px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media (min-width: 1800px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	@media (min-width: 2000px) {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
}

.placeholder {
	opacity: 0.65;
	user-select: none;
	cursor: auto;
	.card-header {
		cursor: auto;
		div {
			span {
				color: #262626;
				background-color: #262626;
				border-radius: var(--property-border-radius);
			}
			&:not(:last-of-type) {
				margin-bottom: 10px;
			}
		}
	}
	.block {
		span {
			color: #262626;
			background-color: #262626;
			border-radius: var(--property-border-radius);
		}
	}
}
</style>
